// 行业
<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 行业管理配置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <h3>行业管理配置</h3>
      <div class="handle-box">
        <span>条件:</span>
        <el-input v-model.trim="name"
                  placeholder="输入行业ID、行业名称"
                  class="handle-input mr10 w100"></el-input>
        <el-button type="primary ml10"
                   icon="el-icon-search"
                   @click="getList">搜索</el-button>
      </div>
      <el-table :data="list"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
        <el-table-column prop="id"
                         label="行业ID"
                         width="120"
                         align="center">
        </el-table-column>
        <el-table-column prop="name"
                         label="行业名称"
                         align="center">
        </el-table-column>
        <!-- <el-table-column prop="id"
                         label="是否需要会员"
                         align="center">
          <template #default="scope">
            <span> {{ scope.row.vip ? "是": "否" }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="name"
                         label="当前状态"
                         align="center">
          <template #default="scope">
            <span> {{ scope.row.enable ? "启用中": "禁用中" }}</span>
          </template>

        </el-table-column>
        <el-table-column label="操作"
                         width="360"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="changeListItem(scope.row)">修改</el-button>
            <el-button type="text"
                       icon="el-icon-circle-close"
                       v-if="scope.row.enable"
                       class="red"
                       @click="changeListStatus(scope.row.id,scope.row)">禁用</el-button>
            <el-button type="text"
                       icon="el-icon-circle-check"
                       v-else
                       @click="changeListStatus( scope.row.id,scope.row)">启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="add-title"
           @click="addList">
        +添加行业
      </div>
      <div class="page">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="pageIndex"
                       :page-size="20"
                       :total="total"
                       @current-change="handlePageChange"></el-pagination>

      </div>
      <el-dialog v-model="showDialog"
                 :title="eaditTitle"
                 width="40%"
                 center>
        <el-form ref="formData"
                 label-width="120px">
          <el-form-item label="行业名称:"
                        required>
            <el-input v-model.trim="query.name"
                      placeholder="中英文限20字，必填"
                      maxlength="20"></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否需要会员:"
                        required>
            <el-radio v-model="query.vip"
                      label="1">是</el-radio>
            <el-radio v-model="query.vip"
                      label="0">否</el-radio>
          </el-form-item> -->
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="saveList">保存</el-button>
            <el-button type="primary"
                       @click="showDialog = false">取消</el-button>
          </span>
        </template>
      </el-dialog>

    </div>

  </div>
</template>

<script>
import { fetchData, getPayList, savePayList, getTradeList, getTradeDataChange, getTradeStatus } from "../../api/index";
// , getTradeDataChange, getTradeStatus
var md5 = require("@/assets/js/md5.min.js");

export default {
  name: "basetable",
  data () {
    return {
      query: {
        vip: "1",
        name: "",
        pageIndex: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      writeFlag: false,// 行编辑状态
      showDialog: false,
      list: [],
      total: 0,// 总条数
      name: "", // 查询条件
      eaditTitle: "添加行业",
      eaditFlag: 0,
      activeData: null,
    };
  },
  created () {
    this.getData();
    this.getList();
    this.getPayData();
  },
  methods: {
    // 获取列表
    getList () {
      let params = {
        name: this.name,     //数字则查id，文字则查名称
        pageIndex: this.query.pageIndex,
        pageSize: 20,
      }
      getTradeList(params).then(res => {
        if (res.code === 0) {
          this.list = res.data.data;
          this.total = res.data.total;
        }
        console.log(res);
      })
    },
    // 修改数据
    changeListItem (data) {
      // 修改
      this.query.name = data.name;
      this.query.vip = data.vip ? "1" : "0";
      this.activeData = data;// 修改数据
      this.eaditFlag = 0;// 修改标志
      this.eaditTitle = "修改行业";
      this.showDialog = true;
    },
    // 添加
    addList () {
      this.eaditFlag = 1;// 添加标志
      this.eaditTitle = "添加行业";
      this.query.name = "";
      this.showDialog = true;

    },
    // 保存数据
    saveList () {
      let id = this.eaditFlag == 0 ? this.activeData.id : "";
      let flag = this.query.vip == 1 ? true : false;
      let params = {
        id: id,     //id 为空则新增，不为空则修改
        name: this.query.name,     //行业名称
        isVip: flag,   //false则是不需要，true是需要
      }
      if (this.query.name.length <= 0) {
        this.$notify.warning({
          title: "提示",
          message: "请输入行业名称"
        });
        return false;
      }

      getTradeDataChange(params).then(res => {
        console.log(res);

        if (res.code === 0) {
          this.$notify.success({
            title: "提示",
            message: id ? "修改成功" : "添加成功"
          });
          this.showDialog = false;

          this.getList();
        } else {
          this.$notify.warning({
            title: "提示",
            message: "操作失败"
          });
        }

      })



    },
    changeListStatus (id, row) {
      let str = row.enable ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          let params = {
            id: id
          };
          getTradeStatus(params).then(res => {
            if (res.code == 0) {
              this.$notify.success({
                title: "提示",
                message: "操作成功"
              });
              this.getList();

            } else {
              this.$notify.warning({
                title: "提示",
                message: "操作失败"
              });

            }
          })
        })
        .catch(() => { });




    },
    changeStatus (row) {
      let str = row.enable ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          row.enable = !row.enable;
          this.savePayData();
        })
        .catch(() => { });
    },
    // 获取列表
    getPayData () {
      let params = {
        ccode: "测试渠道",
        pcode: "PPT_QY",
        uuid: "uuxx12021xee",
        sign: "0BB930E4672BBDE054D0E238FCF63CDE"
      };
      getPayList(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          let list = [];
          list.push(res.data.gold);
          list.push(res.data.lifeLong);
          list.push(res.data.normal);
          this.tableData = list;

        }
      })
    },
    // 保存列表
    savePayData () {
      this.tableData.map(item => {
        let n = String(item.price).indexOf(".");
        if (n > -1) {
          item.price = String(item.price).substr(0, n) + String(item.price).substr(n, 3);

        }
        let m = String(item.rawPrice).indexOf(".");
        if (m > -1) {
          item.rawPrice = String(item.rawPrice).substr(0, m) + String(item.rawPrice).substr(m, 3);
        }
      })

      let normal = this.tableData[2];
      let gold = this.tableData[0];
      let lifeLong = this.tableData[1];
      let priceList = this.tableData.filter(item => {
        return Number(item.price) < 0.01 || Number(item.price) > 9999 || item.price == ""
      });
      if (priceList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "实付价格范围在0.01~9999之间,不能为空"
        });
        return false;
      }

      let rawPriceList = this.tableData.filter(item => {
        return Number(item.rawPrice) < 0.01 || Number(item.rawPrice) > 9999 || item.rawPrice == ""
      })
      console.log(rawPriceList);
      if (rawPriceList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "原始价格范围在0.01~9999之间"
        });
        return false;

      }

      let downCount = this.tableData.filter(item => {
        console.log('====================================');
        console.log(Number(item.downloadTime) < 0, item.downloadTime === "");
        console.log('====================================');
        return Number(item.downloadTime) < 0 || item.downloadTime === ""
      })
      if (downCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "下载总次数大于等于0,不能为空"
        });
        return false;

      }

      let pptDownCount = this.tableData.filter(item => {
        return Number(item.pptDailyTime) < 0 || item.pptDailyTime === ""
      })
      if (pptDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "PPT次数大于等于0，不能为空"
        });
        return false;

      }

      let wordDownCount = this.tableData.filter(item => {
        return Number(item.wordDailyTime) < 0 || item.wordDailyTime === ""
      })
      if (wordDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "Word次数大于等于0,不能为空"
        });
        return false;

      }

      let excelDownCount = this.tableData.filter(item => {
        return Number(item.excelDailyTime) < 0 || item.excelDailyTime === ""
      })
      if (excelDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "Excel次数大于等于0,不能为空"
        });
        return false;

      }
      let sortList = this.tableData.filter(item => {
        return item.sort === "" || Number(item.sort) < 0 || item.name.length <= 0
      })

      if (sortList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "排序和套餐名称为必填项"
        });
        return false;
      } else {
        this.tableData.map(item => {
          item.writeFlag = false;
        })
      }

      let goldStatus = String(gold.enable).replace(String(gold.enable)[0], String(gold.enable)[0].toUpperCase());
      let lifeLongStatus = String(lifeLong.enable).replace(String(lifeLong.enable)[0], String(lifeLong.enable)[0].toUpperCase());
      let normalStatus = String(normal.enable).replace(String(normal.enable)[0], String(normal.enable)[0].toUpperCase());


      let str = "1bca15648633405ab86330d0d97aceb6" + gold.downloadTime + goldStatus +
        gold.excelDailyTime + gold.memberType + gold.name + gold.packageName + gold.pptDailyTime + gold.price + gold.rawPrice + gold.sort +
        gold.wordDailyTime + lifeLong.downloadTime + lifeLongStatus + lifeLong.excelDailyTime + lifeLong.memberType + lifeLong.name +
        lifeLong.packageName + lifeLong.pptDailyTime + lifeLong.price + lifeLong.rawPrice + lifeLong.sort + lifeLong.wordDailyTime +
        normal.downloadTime + normalStatus + normal.excelDailyTime + normal.memberType + normal.name + normal.packageName + normal.pptDailyTime +
        normal.price + normal.rawPrice + normal.sort + normal.wordDailyTime;

      let sign = md5(str).toUpperCase();


      let params = {
        normal,
        gold,
        lifeLong,
        sign
      }
      savePayList(params).then(res => {
        console.log('====================================');
        console.log(res);
        console.log('====================================');
        if (res.code === 0) {
          this.$notify.success({
            title: "提示",
            message: "保存成功"
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.message
          });
        }
      })
    },
    checkNumTwo (e) {
      // 通过正则过滤小数点后两位
      console.log(e);
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;

    },

    //禁用
    DisableRow () {
      this.$confirm('将禁用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '禁用成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消禁用'
        });
      });
    },
    // 启用
    enableRow () {
      this.$confirm('将启用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '启用成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消启用'
        });
      });
    },
    //删除
    delRow () {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


    handleRemove (file) {
      console.log(file);
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload (file) {
      console.log(file);
    },
    // 获取 easy-mock 的模拟数据
    getData () {
      fetchData(this.query).then(res => {
        console.log(res);
        // this.tableData = res.list;
        this.pageTotal = res.pageTotal || 50;
      });
    },
    // 触发搜索按钮
    handleSearch () {
      this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete (index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => { });
    },
    // 多选操作
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    delAllSelection () {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit (index, row) {
      console.log(index);
      console.log(row);
      row.writeFlag = true;
    },
    addGoods () {
      this.showDialog = true;

    },
    handleSave (index, row) {
      console.log(row);
      // row.writeFlag = false;
      this.savePayData();
    },
    // 保存编辑
    saveEdit () {

      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange (val) {
      this.query.pageIndex = val;
      this.getList();
    }
  }
}
</script>

<style scoped lang='scss'>
.handle-box {
  margin-top: 8px;
  margin-bottom: 8px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.container {
  position: relative;
  top: 0;
  left: 0;
}
.add-title {
  margin-top: 8px;
  width: 100%;
  line-height: 40px;
  border-radius: 4px;
  // border: 1px solid #409eff;
  background: #409eff;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.save-good {
  border: 1px solid #67c23a;
  background: #67c23a;
  color: #fff;
}
.page {
  padding: 10px;
  display: flex;
}
</style>
